import React from "react";
import styles from "./Header.module.css";
// import logo from "../../assets/images/logo.png";
import { ReactComponent as Logo } from "../../assets/icons/logo/goit_logo_small.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow/left-arrow.svg";

const Header = ({
  blockTasksArr,
  currentTaskId,
  getPrevTask,
  getNextTask,
  passed,
  blockIndex,
}) => {
  const blockQuestionQuantity = blockTasksArr.length;
  const numberCurrentQuestion = blockTasksArr.indexOf(currentTaskId) + 1;
  const showPrevButton =
    blockTasksArr.indexOf(currentTaskId) === 0 ? false : true;
  const showNextButton =
    blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1 || !passed
      ? false
      : true;
  return (
    <header className={styles.header_container}>
      <div className={styles.headerContent}>
        <h1 className={styles.logo_container}>
          <a href="https://goit.ua/" target="_blank" rel="noopener noreferrer">
            {/* <img src={logo} alt="logo" /> */}
            <Logo width="90" height="30" />
          </a>
          <span className={styles.logo_title}>Знакомство с HTML и CSS</span>
        </h1>

        <div className={styles.questionCounter_container}>
          {showPrevButton && (
            <button onClick={getPrevTask} className={styles.button}>
              <LeftArrow className={styles.arrow} />
            </button>
          )}
          <span className={styles.count}>
            Вопрос {numberCurrentQuestion} / {blockQuestionQuantity}
          </span>
          {showNextButton && (
            <button onClick={getNextTask} className={styles.button}>
              <RightArrow className={styles.arrow} />
            </button>
          )}
        </div>

        <p className={styles.days_container}>
          Марафон:&ensp;<span>день {blockIndex}</span>&ensp;из 6
        </p>
      </div>
    </header>
  );
};

export default Header;
