import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import logo from "../../assets/images/logo.png";
import s from "./PreviewModal.module.css";

const PreviewModal = ({ onClose, cvLink }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <img className={s.img} src={cvLink || logo} alt="cvlink" />
      </div>
    </ModalWrapper>
  );
};

export default PreviewModal;
