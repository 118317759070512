import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./ModalPolitics.module.css";

const ModalPolitics = ({ onClose }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.content}>
        <h2 className={s.modalMainTitle}>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <p className={s.text}>
          Согласившись принимать участие в марафоне вы предоставляете согласие
          на обработку персональных данных, соглашаетесь с предложенными нами
          каналами коммуникации, с возможностью получения рассылок и
          коммерческих предложений. Принимая данную политику вы также
          подтверждаете, что ознакомились с перечнем своих прав как субъекта
          данных.
        </p>

        <h3 className={s.title}>1. СБОР ЛИЧНОЙ ИНФОРМАЦИИ </h3>

        <p className={s.text}>
          1.1. Во время заполнения контактных форм при регистрации мы просим вас
          сообщить необходимую личную информацию в объеме, который является
          необходимым для предоставления услуг.
        </p>

        <p className={s.text}>
          1.2. Файлы cookie - когда вы переходите по ссылкам, мы отправляем один
          или несколько файлов cookie на ваш компьютер или другое устройство.
          Файлы cookie используются для того, чтобы повышать качество
          предоставляемых услуг: сохранять настройки пользователя.
        </p>

        <p className={s.text}>
          1.3. Информация о посещении - при доступе на сайт наши серверы
          автоматически могут записывать определенную информацию. Эти журналы
          сервера могут содержать такую информацию, как веб-запрос, IP-адрес,
          тип и язык браузера, дату и время запроса.
        </p>

        <h3 className={s.title}>2. ЗАЩИТА ИНФОРМАЦИИ</h3>

        <p className={s.text}>
          2.1. Мы принимаем все необходимые меры для защиты данных от
          неавторизованного доступа, изменения, раскрытия или уничтожения. К
          этим мерам относятся, в частности, внутренняя проверка процессов
          сбора, хранения и обработки данных и мер безопасности, включая
          соответствующее шифрование и меры по обеспечению физической
          безопасности данных для предотвращения неавторизованного доступа.
        </p>

        <h3 className={s.title}>
          3. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ{" "}
        </h3>
        <p className={s.text}>
          3.1. Настоящая политика конфиденциальности может время от времени
          меняться. Изменения, вносимые в политику конфиденциальности, будут
          опубликованы в этом документе
        </p>
      </div>
    </ModalWrapper>
  );
};

export default ModalPolitics;
