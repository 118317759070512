const optionsHMTL = {
  // mode: 'xml',
  mode: "htmlmixed", // htmlmixed ; css
  // name: 'html',
  htmlMode: true,
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
};

const optionsHMTLdisable = {
  // mode: 'xml',
  mode: "htmlmixed", // htmlmixed ; css
  // name: 'html',
  htmlMode: true,
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: true,
  lineWrapping: true,
};

const optionsCSS = {
  // mode: 'xml',
  mode: "css",
  // name: 'html',
  htmlMode: true,
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: false,
  lineWrapping: true,
};

const optionsCSSdisable = {
  // mode: 'xml',
  mode: "css",
  // name: 'html',
  htmlMode: true,
  theme: "material",
  tabSize: 4,
  lineNumbers: true,
  readOnly: true,
  lineWrapping: true,
};

export default {
  optionsHMTL,
  optionsCSS,
  optionsHMTLdisable,
  optionsCSSdisable,
};
