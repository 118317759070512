import React from "react";
import styles from "./ButtonHideTheory.module.css";
import { ReactComponent as Badge } from "../../assets/icons/chrome_reader_mode-black-18dp.svg";

const ButtonHideTheory = ({ toggleTheoryAndTask, isTheoryAndTaskShow }) => {
  return (
    <button onClick={toggleTheoryAndTask} className={styles.button}>
      <Badge className={styles.badge} />
      {isTheoryAndTaskShow && <span>Скрыть теорию и задание</span>}
    </button>
  );
};

export default ButtonHideTheory;
