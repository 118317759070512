import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./ModalRefreshQuestion.module.css";

const ModalRefreshQuestion = ({ onClose, refreshCurrentQuestion }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <h2 className={s.title}>
          Вы точно хотите сбросить код в исходное состояние ?
        </h2>
        <div className={s.wrapperButton}>
          <button
            className={s.button}
            onClick={() => {
              refreshCurrentQuestion();
              onClose();
            }}
          >
            Да
          </button>
          <button className={s.button} onClick={onClose}>
            Нет
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalRefreshQuestion;
