import React, { Component } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
// import { ReactComponent as Picture } from "../../assets/icons/photo-black-18dp.svg";
// import PreviewModal from "../PreviewModal/PreviewModal";
// import { CSSTransition } from "react-transition-group";
// import pop3000 from "../../transition/pop3000.module.css";
// import pop1000 from "../../transition/pop1000.module.css";
// import pop1000TheoryHide from "../../transition/pop1000TheoryHide.module.css";
// import pop1000TheoryShowAddCSS from "../../transition/pop1000TheoryShowAddCSS.module.css";
import Timer from "../Timer/Timer";
import VideoModal from "../VideoModal/VideoModal";
import ModalRefreshQuestion from "../ModalRefreshQuestion/ModalRefreshQuestion";

// const animateButtonLayoutAfterThreeMinutes = 180000;

class TaskButtons extends Component {
  state = {
    showPreviewModal: false,
    showPreviewModalFirst: false,
    firstTime: true, // поле которое отвечает за то чтобы только один раз срабатывала анимация,
    showVideoModal: false,
    showModalRefreshQuestion: false,
  };

  // componentDidUpdate(prevProps, prevState) {
  // начало, логика анимации первого дня первого вопроса:
  // const { blockIndex, currentTaskId, blockTasksArr } = this.props;
  // const { firstTime } = this.state;
  // if (
  //   blockIndex === 1 &&
  //   blockTasksArr.indexOf(currentTaskId) === 0 &&
  //   firstTime
  // ) {
  //   this.setState({ showPreviewModalFirst: true, firstTime: false }, () => {
  //     setTimeout(
  //       () => this.setState({ showPreviewModalFirst: false }),
  //       10000
  //     );
  //   });
  // }
  // конец, логика анимации первого дня первого вопроса
  // начало, логика анимации кнопки макеты через 3 минуты
  // const { metadata } = this.props;
  // if (
  //   prevProps.currentTaskId !== currentTaskId &&
  //   metadata &&
  //   metadata.cvLink
  // ) {
  //   // console.log("start timer changeButtonLayout");
  //   setTimeout(
  //     () => this.changeButtonLayout(currentTaskId),
  //     animateButtonLayoutAfterThreeMinutes
  //   );
  // }
  // конец, логика анимации первого дня первого вопроса
  // }
  // openPreviewModal = () => this.setState({ showPreviewModal: true });
  // closePreviewModal = () => this.setState({ showPreviewModal: false });
  // closePreviewModalFirst = () =>
  //   this.setState({ showPreviewModalFirst: false });

  showVideoModal = () => {
    const { showTimer } = this.props;
    if (!showTimer) {
      this.setState({ showVideoModal: true });
    }
  };
  closeVideoModal = () => this.setState({ showVideoModal: false });

  changeButtonLayout = (startTaskId) => {
    const { currentTaskId } = this.props;
    if (startTaskId !== currentTaskId) return;
    document.querySelector("#layout-button").classList.add("enterButtonLayout");
    setTimeout(() => {
      document
        .querySelector("#layout-button")
        .classList.add("exitButtonLayout");

      setTimeout(() => {
        document
          .querySelector("#layout-button")
          .classList.remove("enterButtonLayout");
        document
          .querySelector("#layout-button")
          .classList.remove("exitButtonLayout");
      }, 3000);
    }, 3000);
  };

  openModalRefreshQuestion = () =>
    this.setState({ showModalRefreshQuestion: true });

  closeModalRefreshQuestion = () =>
    this.setState({ showModalRefreshQuestion: false });

  render() {
    const {
      checkTest,
      refreshCurrentQuestion,
      timerEndTime,
      metadata,
      showTimer,
      hideTimer,
      // clientWidth,
      // isTheoryAndTaskShow,
    } = this.props;
    const {
      // showPreviewModal,
      // showPreviewModalFirst,
      showVideoModal,
      showModalRefreshQuestion,
    } = this.state;
    return (
      <div className={styles.buttonsContainer}>
        <button className={styles.checkButton} onClick={checkTest}>
          Проверить
        </button>
        <button
          onClick={this.openModalRefreshQuestion}
          className={styles.buttonWithSvg}
        >
          <Refresh className={styles.svg} />
          Сбросить
        </button>
        {/* {metadata && metadata.cvLink && (
          <button
            className={styles.buttonWithSvg}
            onClick={this.openPreviewModal}
            id="layout-button"
          >
            <Picture className={styles.svg} />
            Макет
          </button>
        )} */}
        {/* {clientWidth > 1200 && ( */}
        <button
          onClick={this.showVideoModal}
          className={
            !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
          }
        >
          <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
          Видеоподсказка
        </button>
        {/* )} */}

        {showTimer && (
          <Timer timerEndTime={timerEndTime} hideTimer={hideTimer} />
        )}

        {/* <CSSTransition
          timeout={1000}
          in={showPreviewModal}
          unmountOnExit
          classNames={
            isTheoryAndTaskShow
              ? metadata.needCss
                ? pop1000TheoryShowAddCSS
                : pop1000
              : pop1000TheoryHide
          }
        >
          <PreviewModal
            onClose={this.closePreviewModal}
            cvLink={metadata.cvLink}
          />
        </CSSTransition> */}

        {/* {showPreviewModal && (
          <PreviewModal
            onClose={this.closePreviewModal}
            cvLink={metadata.cvLink}
          />
        )} */}

        {/* <CSSTransition
          timeout={3000}
          in={showPreviewModalFirst}
          unmountOnExit
          classNames={pop3000}
        >
          <PreviewModal
            onClose={this.closePreviewModalFirst}
            cvLink={metadata.cvLink}
          />
        </CSSTransition> */}

        {showVideoModal && (
          <VideoModal metadata={metadata} onClose={this.closeVideoModal} />
        )}

        {showModalRefreshQuestion && (
          <ModalRefreshQuestion
            onClose={this.closeModalRefreshQuestion}
            refreshCurrentQuestion={refreshCurrentQuestion}
          />
        )}
      </div>
    );
  }
}

export default TaskButtons;
