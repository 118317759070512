import React, { Component } from "react";
import s from "./ModalFinishGiveArchive.module.css";
// import { ReactComponent as Confetti2 } from "../../assets/icons/confetti/confetti-2.svg";
// import { ReactComponent as Confetti3 } from "../../assets/icons/confetti/confetti-3.svg";
// import { ReactComponent as Confetti4 } from "../../assets/icons/confetti/confetti-4.svg";

class ModalFinishGiveArchive extends Component {
  componentDidMount() {
    const Confettiful = function (el) {
      this.el = el;
      this.containerEl = null;

      this.confettiFrequency = 3;
      this.confettiColors = ["#fce18a", "#ff726d", "#b48def", "#f4306d"];
      this.confettiAnimations = ["slow", "medium", "fast"];

      this._setupElements();
      this._renderConfetti();
    };

    Confettiful.prototype._setupElements = function () {
      const containerEl = document.createElement("div");
      const elPosition = this.el.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        this.el.style.position = "relative";
      }

      containerEl.classList.add("confetti-container");

      this.el.appendChild(containerEl);

      this.containerEl = containerEl;
    };

    Confettiful.prototype._renderConfetti = function () {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = Math.floor(Math.random() * 3) + 7 + "px";
        const confettiBackground = this.confettiColors[
          Math.floor(Math.random() * this.confettiColors.length)
        ];
        const confettiLeft =
          Math.floor(Math.random() * this.el.offsetWidth) + "px";
        const confettiAnimation = this.confettiAnimations[
          Math.floor(Math.random() * this.confettiAnimations.length)
        ];

        confettiEl.classList.add(
          "confetti",
          "confetti--animation-" + confettiAnimation
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(function () {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        this.containerEl.appendChild(confettiEl);
      }, 25);
    };

    window.confettiful = new Confettiful(
      document.querySelector("#js-container")
    );

    document.querySelector("#js-container").style.position = "fixed";
  }

  render() {
    return (
      <article
        style={{ position: "fixed" }}
        className={s.wrapper}
        id="js-container"
      >
        <div className={s.contentWrapper}>
          <h2 className={s.modalTitle}>ПОЗДРАВЛЯЕМ!</h2>
          {/* <Confetti2 className={s.confetti2} />
          <Confetti3 className={s.confetti3} />
          <Confetti4 className={s.confetti4} /> */}

          <div className={s.textWrapper}>
            <p className={s.fatText}>
              Поздравляем с успешным завершением марафона!
            </p>

            <p className={s.text}>Твои задания выполнены на 100%.</p>
            <p className={s.text}>
              Всего выполнено 52 задания. Отличный результат!
            </p>

            <p className={s.fatText}>Вот твой приз:</p>
            <div className={s.buttonContainer}>
              <a href="https://cutt.ly/ct92dkg" className={s.button}>
                Скачать архив с кодом
              </a>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default ModalFinishGiveArchive;
