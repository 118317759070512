import React, { Component, createRef } from "react";
import logo from "../../assets/images/logo.png";
import PreviewModal from "../PreviewModal/PreviewModal";
import s from "./TheoryAndTaskOrLayout.module.css";

class TheoryAndTaskOrLayout extends Component {
  state = {
    showPreviewModal: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.htmlDescription !== this.props.htmlDescription) {
      this.scrollToTopTheoryAndTask();
    }
  }

  theoryRef = createRef();

  showPreviewModal = () => {
    this.setState({ showPreviewModal: true });
  };

  hidePreviewModal = () => {
    this.setState({ showPreviewModal: false });
  };

  scrollToTopTheoryAndTask = () => {
    const { isTheoryAndTaskShow, isServerError } = this.props;
    if (isTheoryAndTaskShow && !isServerError && this.theoryRef.current) {
      this.theoryRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { htmlDescription, cvLink } = this.props;
    const { showPreviewModal } = this.state;
    return (
      <section ref={this.theoryRef} className={s.wrapper}>
        <div
          className={s.theoryAndTask_container}
          dangerouslySetInnerHTML={{ __html: htmlDescription }}
        />

        {cvLink && (
          <>
            <h4 className={s.layoutTitle}>Макет</h4>
            <p className={s.layoutText}>
              * чтобы увеличить, кликни по картинке
            </p>
            <img
              onClick={this.showPreviewModal}
              className={s.layout}
              src={cvLink || logo}
              alt="cvlink"
            />
          </>
        )}

        {showPreviewModal && (
          <PreviewModal onClose={this.hidePreviewModal} cvLink={cvLink} />
        )}
      </section>
    );
  }
}

export default TheoryAndTaskOrLayout;
