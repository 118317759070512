import React from "react";
import { ReactComponent as Reset } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Collapse } from "../../assets/icons/collapse.svg";
import s from "./BigBrowserHeader.module.css";

const BigBrowserHeader = ({ refreshCurrentQuestion, onClose }) => {
  return (
    <div className={s.container}>
      <button onClick={refreshCurrentQuestion} className={s.button}>
        <Reset className={s.icon} />
      </button>
      <span className={s.link}>https://goit.marathon.html.css/</span>
      <button onClick={onClose} className={s.button}>
        <Collapse className={s.icon2} />
      </button>
    </div>
  );
};

export default BigBrowserHeader;
