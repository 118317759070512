import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
// import ModalWrapperWithoutClose from "../ModalWrapperWithoutClose/ModalWrapperWithoutClose";
import s from "./ModalUnresolvedDays.module.css";

const ModalUnresolvedDays = ({ nonFinishedDays }) => {
  return (
    <ModalWrapper onClose={() => null} hideCloseButton>
      {/* <ModalWrapperWithoutClose> */}
      <div className={s.wrapper}>
        <h2 className={s.modalTitle}>Дорогой марафонец!</h2>

        <p className={s.text}>Ты отлично поработал сегодня!</p>

        <p className={s.text}>
          Чтобы получить главный приз - архив с кодом, остается завершить работу
          над некоторыми заданиями:
        </p>
        <ul className={s.unresolvedDaysList}>
          {nonFinishedDays &&
            nonFinishedDays.map((el) => {
              return (
                <li key={el[0]}>
                  <span>День {el[0]}</span>
                  <a href={el[1]} target="_blank" rel="noopener noreferrer">
                    {el[1]}
                  </a>
                </li>
              );
            })}
        </ul>
        <p className={s.fatText}>У тебя все получится!</p>

        {/* <div className={s.buttonContainer}>
            <button type="button" className={s.button} onClick={onClose}>
              Ok
            </button>
          </div> */}
      </div>
      {/* </ModalWrapperWithoutClose> */}
    </ModalWrapper>
  );
};

export default ModalUnresolvedDays;
