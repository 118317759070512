import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./ResultModal.module.css";

const ResultModal = ({
  onClose,
  successConditions,
  failedConditions,
  taskSuccessfullyPassed,
  // getTask,
  getNextTask,
  // openCongratsModal,
  // эти два пропа чтобы понять крайний ли это вопрос
  blockTasksArr,
  currentTaskId,
}) => {
  // const addFunctionOpenCongratsModal =
  // blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1
  //   ? false
  //   : true;

  const showButtonNextQuestion =
    blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1
      ? false
      : true;
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <p className={s.modalTitle}>Результат: </p>
        {successConditions.length > 0 && (
          <p className={s.successTitle}>Успешно: </p>
        )}
        <ol>
          {successConditions.map((element) => (
            <li key={element}>{element}</li>
          ))}
        </ol>
        {!taskSuccessfullyPassed && <p className={s.errorTitle}>Ошибки:</p>}
        {!taskSuccessfullyPassed && (
          <ol>
            {failedConditions.map((element) => (
              <li key={element}>{element}</li>
            ))}
          </ol>
        )}

        <div className={s.buttons_container}>
          {!taskSuccessfullyPassed && (
            <button
              type="button"
              className={s.button}
              onClick={() => {
                onClose();
              }}
            >
              Продолжить
            </button>
          )}

          {taskSuccessfullyPassed && showButtonNextQuestion && (
            <button
              type="button"
              className={s.button}
              onClick={() => {
                onClose();
                getNextTask();
              }}
            >
              Перейти к следующему вопросу
            </button>
          )}

          {!showButtonNextQuestion && taskSuccessfullyPassed && (
            <button
              type="button"
              className={s.button}
              onClick={() => {
                onClose();
                // openCongratsModal();
              }}
            >
              Ок
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ResultModal;
