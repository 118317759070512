import React from "react";
import Frame from "react-frame-component";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import BigBrowserHeader from "../BigBrowserHeader/BigBrowserHeader";
import s from "./BrowserResultModal.module.css";

const BrowserResultModal = ({
  onClose,
  refreshCurrentQuestion,
  valueCSS,
  resultView
}) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <BigBrowserHeader
          refreshCurrentQuestion={refreshCurrentQuestion}
          onClose={onClose}
        />
        <Frame
          initialContent='<!DOCTYPE html><html><head><link href="https:fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet"><style>body,a{font-family:"Montserrat",sans-serif;pointer-events: none;};</style><style>*::-webkit-scrollbar{width:5px;height:5px;}::-webkit-scrollbar-track-piece{background-color:transparent;}::-webkit-scrollbar-thumb{height:5px;background-color:rgba(193,193,193,0.5);border-radius:10px;}</style></head><body><div><div></body></html>'
          style={{
            width: "100%",
            height: "80vh",
            border: "0",
            overflowY: "auto",
            fontFamily: "'Montserrat', sans-serif",
            padding: "0 10px"
          }}
        >
          <div>
            <style>{valueCSS}</style>
            <div dangerouslySetInnerHTML={resultView} />
          </div>
        </Frame>
      </div>
    </ModalWrapper>
  );
};

export default BrowserResultModal;
